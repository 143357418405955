.input {
    display: grid;
    row-gap: 8px;

    &__control {
        height: 66px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        color: var(--color-main-white);
        font-size: 25px;
        line-height: 1.2;
        border-bottom: 1px solid var(--color-main-white);
    }

    &::placeholder {
        color: var(--color-gray);
    }

    @media screen and (max-width: 767px) {
        &__control {
            height: 42px;
            font-size: 16px;
        }
    }
}