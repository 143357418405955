.all-project-comments {
    padding-top: 80px;

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__link {
        margin-bottom: 25px;

        @media screen and (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__title {
        margin-bottom: 32px;

        @media screen and (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__actions {
        display: flex;
        column-gap: 30px;
        margin-bottom: 20px;

        @media screen and (max-width: 480px) {
            column-gap: 8px;
        }
    }

    &__button {
        width: 195px;
    }

    &__comment {
        &-list {
            display: grid;
            row-gap: 16px;
            max-height: 57vh;
            padding-right: 16px;

            @media screen and (max-width: 480px) {
                row-gap: 8px;
            }

            &::-webkit-scrollbar {
                width: 10px;
                background-color: transparent;
                border: 1px solid var(--color-main-white);
                z-index: 1;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--color-main-white);
            }

            padding-bottom: 30px;
            overflow-y: auto;
        }
    }

    @media screen and (max-width: 480px) {
        padding-top: 20px;

        .button--simply {
            white-space: nowrap;
            width: 91px;
            height: 32px;
            font-size: 14px;
            padding: 5px 7px;
        }
    }

}