.checkbox {
    display: flex;
    align-items: center;
    column-gap: 8px;
    transition: var(--checkbox-transition);

    &:hover {
        .checkbox__control {
            box-shadow: 0 0 22px var(--color-main-green);
        }
    }

    &__control {
        --checkbox-size: 28px;
        position: relative;
        appearance: none;
        width: var(--checkbox-size);
        height: var(--checkbox-size);
        border: 1px solid var(--color-main-green);
        flex-shrink: 0;
        transition: 0.55s;

        @media (max-width: 480px) {
            --checkbox-size: 22px;
        }

        &:checked {
            background-color: var(--color-main-green);

            &::after {
                opacity: 1;
            }
        }

        &::after {
            content: url("../../../../assets/image/icons/check.svg");
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: 0.55s;
        }
    }

    &__label {
        color: var(--color-gray);
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.025em;
        user-select: none;

    }

    @media screen and (max-width: 767px) {
        &__label {
            font-size: 11px;
        }
    }
}