.project {
    &__comments {
        margin-bottom: 80px;

        @media (max-width: 767px) {
            margin-bottom: 28px;
        }

        &-header {
            margin-bottom: 60px;

            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
        }

        &-wrapper {
            position: absolute;
            width: 100%;
        }


        &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &--link {
                color: var(--color-main-white);
                text-decoration: underline;
                white-space: nowrap;
            }
        }

        &-list {
            display: flex;
            column-gap: 30px;
            padding: 5px;

            @media (max-width: 767px) {
            column-gap: 20px;
            }

            @media (max-width: 480px) {
                column-gap: 12px;
            }
        }

        &-item {
            &:last-child {
                padding-right: 20px;
            }
        }
    }
}