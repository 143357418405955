.loading {
    --loading-offset: 150;
    --loading-duration: 1.2s;

    &.is-full {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(6, 11, 17, 0.5);;
    }

    &__spinner {
        animation: rotator var(--loading-duration) linear infinite;

        &-path {
            stroke-dasharray: var(--loading-offset);
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: dash var(--loading-duration) ease-in-out infinite,
            colors calc(var(--loading-duration) * 4) ease-in-out infinite;
        }
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}


@keyframes colors {
    0% {
        stroke: #4285F4;
    }
    25% {
        stroke: #DE3E35;
    }
    50% {
        stroke: #F7C223;
    }
    75% {
        stroke: #1B9A59;
    }
    100% {
        stroke: #4285F4;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: var(--loading-offset);
    }
    50% {
        stroke-dashoffset: var(--loading-offset)/4;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: var(--loading-offset);
        transform: rotate(450deg);
    }
}