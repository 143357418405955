.monitor-progress-box {
    position: relative;
    border: 1px solid var(--color-main-green);
    padding: 17px;

    @media (max-width: 1024px) {
        padding: 8px;
    }

    @media (max-width: 767px) {
        height: 286px;
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__text {
        text-align: center;
    }

    &__up {
        height: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: url("../../../../shared/assets/image/icons/corn.svg");
            transform: translate(-16%, 22%);
            rotate: 90deg;
            filter: var(--drop-shadow-corn);
        }

        &::before {
            position: absolute;
            top: -1px;
            right: 0;
            content: url("../../../../shared/assets/image/icons/corn.svg");
            transform: translate(-16%, 22%);
            rotate: 180deg;
            filter: var(--drop-shadow-corn);
        }
    }

    &__down {
        height: 0;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: url("../../../../shared/assets/image/icons/corn.svg");
            transform: translate(-16%, 22%);
            filter: var(--drop-shadow-corn);
        }

        &::before {
            position: absolute;
            bottom: 0;
            right: 0;
            content: url("../../../../shared/assets/image/icons/corn.svg");
            transform: translate(-16%, 22%);
            rotate: 270deg;
            filter: var(--drop-shadow-corn);
        }
    }
}