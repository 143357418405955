.link-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    height: 174px;
    background-color: #17171A;
    padding: 32px 0;
    text-decoration: none;
    color: var(--color-main-white);
    margin-top: 58px;

    @media (max-width: 767px) {
        font-size: 14px;
        text-align: center;
        padding-inline: 5px;
        gap: 12px;
    }

    &__image {
        @media (max-width: 480px) {
            width: 46px;
            height: 46px;
        }
    }
}