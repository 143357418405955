.progress-strip-column {
    width: 222px;

    @media (max-width: 767px) {
        width: 180px;
    }

    @media (max-width: 480px) {
        width: 134px;
    }

    &__inner {
        position: relative;
    }

    &:not(:hover) {
        .progress-strip-column__tooltip {
            visibility: hidden;
            opacity: 0;
            transform: translate(-50%, -120%);
        }

        .progress-strip-column__icon {
            opacity: 1;
        }
    }

    &__tooltip {
        color: #000000;
        text-align: center;
        font-size: 25px;
        font-weight: 400;
        line-height: 1.25;
        position: absolute;
        min-width: 225px;
        bottom: 100%;
        left: 50%;
        padding: 10px 22px 10px 22px;
        transform: translate(-50%, 0);
        background-color: var(--color-main-white);
        transition: 0.35s;

        @media (max-width: 767px) {
            min-width: 180px;
            font-size: 18px;
            padding: 6px 12px 6px 12px;
        }

        @media (max-width: 480px) {
            font-size: 14px;
            min-width: 140px;
            padding: 6px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -26px;
            border: 10px solid transparent;
            border-top: 17px solid var(--color-main-white);
            transform: translate(-50%, 0);
        }
    }

    &__icon {
        width: 100%;
        opacity: 0;
        transition: 0.35s;
    }

    &__body {
        background-color: var(--color-main-green);
        height: 32px;
        width: 100%;
    }


    &.is-accepted {
        .progress-strip-column__body {
            background-color: var(--color-light-black);
        }

        .progress-strip-column__icon {
            visibility: hidden;
            opacity: 0;
        }
    }

    &.is-process {
        .progress-strip-column__body {
            background-color: var(--color-yellow);
        }

        .progress-strip-column__icon {
            path {
                fill: var(--color-yellow);
            }
        }
    }

    &.is-done {
        .progress-strip-column__body {
            background-color: var(--color-main-green);
        }
    }
}