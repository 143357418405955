.stage {
    --stage-transition: 0.35s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 79px;
    border-right: 0;
    border-left: 0;
    transition: var(--stage-transition);

    @media (max-width: 767px) {
        height: 70px;
    }

    @media (max-width: 480px) {
        height: 60px;
    }

    &__text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        overflow-wrap: anywhere;
        max-width: 150px;
    }

    &::after {
        content: '';
        position: absolute;
        right: -26px;
        width: 56px;
        height: 56px;
        transform: rotate(-45deg);
        z-index: 1;
        background-color: var(--color-main-black);
        transition: var(--stage-transition);

        @media (max-width: 767px) {
            right: -25px;
            width: 50px;
            height: 50px;
        }

        @media (max-width: 480px) {
            right: -21px;
            width: 42px;
            height: 42px;
        }
    }

    &--release {
        border: 1px solid #00EE66;
        border-right: 0;
        border-left: 0;

        .stage__text {
            color: var(--color-main-green);
        }

        &.is-active {
            background-color: var(--color-main-green);

            .stage__text {
                color: var(--color-main-black);
            }

            &::after {
                background-color: var(--color-main-green);
            }
        }

        &::after {
            border: 1px solid #00EE66;
            border-width: 0px 1px 1px 0px;
        }
    }

    &--proccess {
        border: 1px solid #FCFF7B;
        border-right: 0;
        border-left: 0;

        .stage__text {
            color: var(--color-yellow);
        }

        &.is-active {
            background-color: var(--color-yellow);

            .stage__text {
                color: var(--color-main-black);
            }

            &::after {
                background-color: var(--color-yellow);
            }
        }

        &::after {
            border: 1px solid #FCFF7B;
            border-width: 0px 1px 1px 0px;
        }
    }

    &--uncomplete {
        border: 1px solid #888;
        border-right: 0;
        border-left: 0;

        .stage__text {
            color: var(--color-gray);
        }

        &.is-active {
            background-color: var(--color-gray);

            .stage__text {
                color: var(--color-main-black);
            }

            &::after {
                background-color: var(--color-gray);
            }
        }

        &::after {
            border: 1px solid #888;
            border-width: 0px 1px 1px 0px;
        }
    }


    &__text {
        position: relative;
        z-index: 2;
        padding-left: 20px;
    }
}