.popup {
    position: fixed;
    width:100%;
    height:100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: all .4s linear 0s;

    &--active {
        visibility: visible;
        opacity: 1;
    }

    &__close-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        visibility: hidden;
        top: 18px;
        right: 18px;
    }

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #060B11;
        box-shadow: 0 0 400px #00EE66;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: 481px;
        z-index: 11;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    &--big {

        .popup__container {
            width: 546px;
        }
    }

    @media screen and (max-width: 767px) {

        &__close-icon {
            cursor: pointer;
            visibility: visible;
            background-color: #35373B;
            border-radius: 50%;
            object-fit: cover;
            background-position: center;
            padding: 2px;
        }

        .popup__container {

            width: 282px;

            .title {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}