.button {
    cursor: pointer;
    font-size: 20px;
    position: relative;
    width: 100%;
    height: 70px;
    color: #000;
    background-color: #00EE66;
    letter-spacing: 0.01em;
    text-align: center;
    transition: 0.45s;

    @media (max-width: 480px) {
        height: 51px;
    }

    &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border: 1px solid var(--color-main-white);
    }

    &:hover {
        box-shadow: 0 0 22px 0 var(--color-main-green);
    }

    &-contact {
        &:hover {
            box-shadow: 0 0 22px 0 var(--color-main-green);
            transform: scale(1);
        }
    }

    &--big {
        font-size: 28px;
        line-height: 1.25;
        letter-spacing: 0.01em;
        font-weight: 500;

        @media (max-width: 480px) {
            font-size: 20px;
        }
    }

    &--big-after {
        &::after {
            top: -5px;
            left: -5px;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
        }

        .button__up {
            &::after {
                top: -6px;
                left: -6px;
            }

            &::before {
                top: -6px;
                right: -6px;
                rotate: 90deg;
            }
        }

        .button__down {
            &::after {
                bottom: -6px;
                left: -6px;
                rotate: 270deg;
            }

            &::before {
                bottom: -6px;
                right: -6px;
                rotate: 180deg;
            }
        }
    }

    &--no-background {
        background: none;
        border: 0;

        &:hover {
            background-color: var(--color-dark-green);
            box-shadow: var(--box-shadow-green);
        }
    }

    &--login {
        width: 117px;
        height: 43px;
        background: none;
        color: #fff;
        border: 1px solid #00EE66;

        &:hover {
            background-color: var(--color-dark-green);
            box-shadow: var(--box-shadow-green);
        }
    }

    &--simply {
        color: var(--color-main-white);
        font-size: 20px;
        font-weight: 400;
        height: 50px;
        letter-spacing: 0;
        padding-left: 25px;
        padding-right: 25px;
        background-color: transparent;
        border: 1px solid var(--color-main-white);

        &.is-active {
            color: var(--color-main-black);
            background-color: var(--color-main-green);
            border: 0;
        }

        .button__up, .button__down {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    &--form {
        height: 71px;
        background: none;
        color: #fff;
        border: 1px solid #F0EFF2;
        padding: 10px 0;
    }

    &--small {
        height: 43px;
    }

    &__up, &__down {
        height: 0;

        &:after, &:before {
            position: absolute;
            // content: url("../../assets/image/icons/corn-white.svg");
            content: "";
            background-image: url("../../assets/image/icons/corn-white.svg");
            width: 7px;
            height: 7px;
        }
    }

    &__up {
        &::after {
            top: -4px;
            left: -4px;
        }

        &::before {
            top: -4px;
            right: -4px;
            rotate: 90deg;
        }
    }

    &__down {
        &::after {
            bottom: -4px;
            left: -4px;
            rotate: 270deg;
        }

        &::before {
            bottom: -4px;
            right: -4px;
            rotate: 180deg;
        }
    }
}