.about {
    position: relative;
    padding-top: var(--section-padding-top);
    padding-bottom: var(--section-padding-bottom);

    &__body {
        --about-padding-right: 320px;
        max-width: calc(947px + var(--about-padding-right) );
        padding-right: var(--about-padding-right);

        @media (max-width: 1280px) {
            --about-padding-right: 290px;
        }

        @media (max-width: 767px) {
            --about-padding-right: 150px;
        }

        @media (max-width: 480px) {
            --about-padding-right: 80px;
        }
    }

    &__title {
        margin-bottom: 25px;

        @media (max-width: 767px) {
            margin-bottom: 16px;
        }
    }

    &__text {
        display: grid;
        row-gap: 32px;

        @media (max-width: 767px) {
            row-gap: 16px;
        }
    }

    &__video {
        position: absolute;
        z-index: -1;
        right: 0;
        top: -20%;
        scale: 0.8;
        transform: translate(55%, 0);

        @media (max-width: 1280px) {
            width: 1600px;
            top: -10%;
        }

        @media (max-width: 767px) {
            width: 1300px;
            top: 0;
            transform: translate(65%, 0);
        }

        @media (max-width: 480px) {
            width: 800px;
            top: 5%;
        }
    }

    &__video-wrapper {
        position: absolute;
        overflow: clip;
        height: 800px;
        width: 100%;
        right: 0;
        top: 0;
        pointer-events: none;
    }
}