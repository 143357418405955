.project {
    padding-block: 35px;

    &__link {
        display: flex;
        align-items: center;
        width: fit-content;
        word-spacing: 10px;
        margin-bottom: 41px;
    }

    @media (max-width:767px) {
        padding: 8px 0 28px;
    }
}