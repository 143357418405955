.request__radios {
    &-body {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        margin-bottom: 25px;
    }

    &-title {
        text-align: center;
        margin-bottom: 24px;
    }

    @media screen and (max-width: 767px) {
        .text {
            font-size: 20px;
        }
    }
}