.comment {
    font-family: "Exo", sans-serif;
    font-size: 20px;
    line-height: 1.33;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
    padding: 16px 24px 16px 24px;

    &.is-accepted {
        border: 1px solid var(--color-main-white);

        .comment__status {
            color: var(--color-main-white);
            border: 1px solid var(--color-main-white);
        }
    }

    &.is-process {
        border: 1px solid var(--color-yellow);

        .comment__status {
            color: var(--color-yellow);
            border: 1px solid var(--color-yellow);
        }

        .comment__info-time {
            opacity: 1;

            @media screen and (max-width: 480px) {
                font-size: 14px;
            }
        }
    }

    &.is-done {
        border: 1px solid var(--color-main-green);

        .comment__status {
            color: var(--color-main-green);
            border: 1px solid var(--color-main-green);
        }
    }

    &__status {
        --comment-status-pandding: 16px;
        font-weight: 500;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        padding: 0 var(--comment-status-pandding) 0 var(--comment-status-pandding);
    }

    &__text {
        font-weight: 300;
        overflow-wrap: anywhere;
    }

    &__info {
        display: flex;
        align-items: center;
        column-gap: 8px;

        &-time {
            opacity: 0.5;
            font-family: "Raleway", sans-serif;
            font-weight: 300;
            color: var(--color-main-white);
            font-size: 16px;
        }
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;

        &__done-icon, &__proccess-icon {
            width: 12px;
            height: 12px;
        }
    }
}