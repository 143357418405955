.mobile-menu {
    position: absolute;
    padding: 0 16px 16px 16px;
    background-color: var(--color-main-black);
    top: 100%;
    left: 0;
    transition: 0.35s;
    z-index: 5;

    &:not(.is-opened) {
        opacity: 0;
        visibility: hidden;
    }

    &__list {
        display: grid;
        row-gap: 8px;
    }
}