.project-stages-task {
    &__title {
        margin-bottom: 32px;

        @media (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__actions {
        display: flex;
        column-gap: 30px;
        margin-bottom: 62px;

        @media (max-width: 767px) {
            column-gap: 8px;
            margin-bottom: 35px;
        }

        @media (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__button {
        width: 194px;
        white-space: nowrap;

        @media (max-width: 767px) {
            font-size: 18px;
        }

        @media (max-width: 480px) {
            font-size: 16px;
            height: 32px;
            padding-inline: 8px;
        }
    }

    &__task-list {
        max-width: 757px;
        display: grid;
        row-gap: 15px;
    }
}