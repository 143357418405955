.services {
    padding-top: var(--section-padding-top);
    padding-bottom: var(--section-padding-bottom);

    .title {
        font-size: clamp(28px, 5vw, 54px);
        overflow-wrap: anywhere;
    }

    &__title {
        margin-bottom: 60px;
    }
}