.slider {
    overflow: clip;

    &__body {
        overflow-y: hidden;
        overflow-x: scroll;

         &.is-dragged {
            pointer-events: none;
             user-select: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
