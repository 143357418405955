.progress-strip {
    &__stage {
        &-list {
            display: flex;
            padding-top: 100px;
            column-gap: 2px;

            @media (max-width: 767px) {
                padding-top: 60px;
            }

        }
    }

    &__swiper {
        padding-top: 80px !important;
    }
}