.preview {
    --preview-transition-button: 1.2s;
    position: relative;
    background: url("../../shared/assets/image/backgrounds/main_background.png") center/cover no-repeat;
    padding-top: 70px;
    padding-bottom: 400px;

    @media (max-width: 1280px) {
        padding-bottom: 250px;
    }

    @media (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 38px;
        height: 700px;
    }

    @media (max-width: 480px) {
        height: 500px;
    }

    &__inner {
        height: 100%;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    &__title {
        margin-bottom: 64px;

        @media (max-width: 1024px) {
            margin-bottom: 45px;
        }
    }

    &__text {
        margin-bottom: 163px;

        @media (max-width: 1024px) {
            margin-bottom: 127px;
        }

        @media (max-width: 767px) {
            margin-bottom: 50px;
        }

        @media (max-width: 480px) {
            margin-bottom: 34px;
        }
    }

    &__button {
        min-width: 193px;
        transition: var(--preview-transition-button);

        &::after {
            transition: var(--preview-transition-button);
        }

        .button__up, .button__down {
            &::after, &::before {
                transition: var(--preview-transition-button);
            }
        }

        &:hover {
            &::after {
                transition: var(--preview-transition-button);
                transform: scale(1.1);
            }

            .button__up {
                &::after {
                    transition: var(--preview-transition-button);
                    top: -6px;
                    left: -14px;
                }

                &::before {
                    transition: var(--preview-transition-button);
                    top: -6px;
                    right: -14px;
                    rotate: 90deg;
                }
            }

            .button__down {
                &::after {
                    transition: var(--preview-transition-button);
                    bottom: -6px;
                    left: -14px;
                }

                &::before {
                    transition: var(--preview-transition-button);
                    bottom: -6px;
                    right: -14px;
                }
            }
        }
    }

    &__button-wrapper {
        @media (min-width: 767px) {
            &:hover {
                .preview__button {
                    filter: brightness(130%);
                    box-shadow: 0 0 100px 100px rgba(0, 238, 102, 0.2);
                }

                .preview__hand {
                    transform: translate(18%, -22%);

                    @media (max-width: 1280px) {
                        transform: translate(22%, -4%);
                    }

                    @media (max-width: 1024px) {
                        transform: translate(20%, -10%);
                    }
                }

                .preview__hand-info {
                    transform: translate(-97%, 10%);
                    @media (max-width: 1280px) {
                        transform: translate(-100%, 18%);
                    }
                    @media (max-width: 1024px) {
                        transform: translate(-100%, 5%);
                    }
                }
            }
        }
    }

    &__hand-wrapper {
        position: absolute;
        bottom: -35%;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: clip;
        pointer-events: none;
    }

    &__hand {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(68%, 0);
        transition: var(--preview-transition-button);

        @media (max-width: 1700px) {
            transform: translate(40%, 0);
        }

        @media (max-width: 1280px) {
            top: 25%;
            transform: translate(43%, 0);
            width: 600px;
        }

        @media (max-width: 767px) {
            top: 10%;
            width: 450px;
            transform: translate(2%, 0);
        }

        @media (max-width: 480px) {
            top: 10%;
            transform: translate(1%, 0);
            width: 250px;
        }
    }

    &__hand-info {
        position: absolute;
        top: -35%;
        left: 50%;
        transform: translate(-108%, 0);
        transition: var(--preview-transition-button);
        pointer-events: none;

        @media (max-width: 1700px) {
            top: -45%;
            transform: translate(-100%, 0);
        }

        @media (max-width: 1280px) {
            width: 700px;
            top: -18%;
            left: 50%;
            transform: translate(-130%, 0);
        }

        @media (max-width: 1024px) {
            left: 50%;
            transform: translate(-115%, 0);
        }

        @media (max-width: 767px) {
            width: 500px;
            top: 2%;
            transform: translate(-85%, 0);
        }

        @media (max-width: 480px) {
            top: 8%;
            width: 300px;
        }
    }

    &__hands-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        &-light {
            position: relative;
            z-index: 2;
            box-shadow: 0 0 71px 71px rgba(0, 238, 102, 0.6);

            @media (max-width: 480px) {
                box-shadow: 0 0 71px 71px rgba(0, 238, 102, 0.6);
            }
        }
    }
}
