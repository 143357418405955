.contact {
    text-align: center;
    padding-bottom: 120px;

    .title {
        font-size: clamp(28px, 5vw, 54px);
    }

    &__title {
        margin-bottom: 50px;
    }

    &__row {
        display: grid;
        row-gap: 32px;

        .title {
            font-size: clamp(16px, 5vw, 54px);
        }
        
        &:not(:last-child) {
            margin-bottom: 60px;
        }
    }

    &__value {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 30px;
    }

    @media screen and (max-width: 767px) {

        padding-bottom: 63px;

        &__title {
            margin-bottom: 16px;
        }

        &__row {
            row-gap: 8px;
            
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        &__value{ 

            column-gap: 8px;
            
            &-image {
                box-sizing: border-box;
                width: 25px;
                height: 21px;
            }
        }

    }
}