.footer {
    --footer-column-gap: 88px;
    padding-block: 76px;


    @media (max-width: 1152px) {
        --footer-column-gap: 38px;
    }

    @media (max-width: 767px) {
        --footer-column-gap: 27px;
        padding-block: 40px;
    }

    @media (max-width: 480px) {
        --footer-column-gap: 10px;
        padding-block: 12px;
    }

    &__body {
        display: flex;
        align-items: center;
        column-gap: var(--footer-column-gap);

        @media (max-width: 640px) {
            justify-content: space-between;
        }
    }

    &__content {
        display: flex;
        column-gap: var(--footer-column-gap);
    }

    &__list {
        display: flex;
        column-gap: var(--footer-column-gap);
        align-items: center;

        @media (max-width: 640px) {
            text-align: left;
            flex-direction: column;
            row-gap: 8px;
        }
    }

    &__contact {
        display: flex;
        column-gap: 14px;
        align-items: center;

        @media (max-width: 640px) {
            flex-direction: column;
            row-gap: 8px;
        }
    }

    &__logo {
        @media (min-width: 641px) {
            margin-right: auto;
        }
    }
}