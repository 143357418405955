.project-stages {
    &__title {
        margin-bottom: 60px;

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }

        @media (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__stage {
        &-list {
            display: flex;
        }
    }

    &__container {
        margin-bottom: 60px;

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }

        @media (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__stage-item:first-child .stage {
        &--release {
            border-left: 1px solid #00EE66;
        }

        &--proccess {
            border-left: 1px solid #FCFF7B;
        }

        &--uncomplete {
            border-left: 1px solid #888;
        }
    }
}