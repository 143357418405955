.task {
    padding: 22px 30px 22px 30px;
    background-color: var(--color-light-black);

    @media (max-width: 767px) {
        padding: 12px 16px 12px 16px;
    }

    @media (max-width: 480px) {
        padding: 8px 16px 8px 16px;
    }

    &__body {
        display: flex;
        column-gap: 16px;
        align-items: center;
    }

    &__status {
        --task-status-size: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--task-status-size);
        height: var(--task-status-size);
        border-radius: 50%;
        background-color: var(--color-dark-gray);

        @media (max-width: 480px) {
            --task-status-size: 23px;
        }

        &-icon {
            @media (max-width: 480px) {
                height: 13px;
            }
        }

        &-check {
            & path {
                fill: var(--color-main-green);
            }
        }
    }
}