.request__popup {
    padding: 40px 30px 40px 30px;

    &-title {
        text-align: center;
        margin-bottom: 40px;
    }

    &-link {
        color: var(--color-main-white);
        text-decoration: underline;
    }
}