.project {
    &__comment {
        width: 420px;
        height: 269px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 20px 20px 25px;

        @media (max-width: 767px) {
            width: 365px;
            height: 222px;
        }

        @media (max-width: 480px) {
            padding: 12px;
            height: 190px;
            width: 220px;
        }

        &--release {
            background-color: #00EE66;
        }

        &--in-progress {
            background-color: #FCFF7B;
        }

        &--accept {
            background-color: #F0EFF2;
        }

        &-bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-text {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            overflow-wrap: anywhere;
        }

        &-status {
            width: 39px;
            height: 39px;
            text-align: center;
            border-radius: 50%;
            background-color: #F0EFF2;
        }
    }
}