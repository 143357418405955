body {
    font-family: 'Exo2', sans-serif;
    color: var(--color-main-white);
    overflow-x: hidden;
    background-color: var(--color-main-black);

    &.is-lock {
        overflow: hidden;
    }
}


html, body {
    scrollbar-gutter: stable;
}

html, body, #root, .app {
    min-height: 100vh;
}

.app {
    display: flex;
    flex-direction: column;
}

img {
    max-width: 100%;
    height: auto;
}
