.stages-development-box {
    box-sizing: border-box;
    border: 1px solid var(--color-main-green);
    width: 416px;
    height: 438px;
    padding: 35px 35px 45px 35px;
    overflow-wrap: break-word;

    @media (max-width: 767px) {
        padding: 20px;
        width: 240px;
        height: 260px;
    }

    @media (max-width: 480px) {
        width: 140px;
        height: 160px;
        padding: 10px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__text {
        @media (max-width: 480px) {
            font-size: 14px !important;
        }
    }
}