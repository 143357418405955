.monitor-progress {
    padding-top: var(--section-padding-top);
    padding-bottom: var(--section-padding-bottom);

    &__header {
        max-width: 887px;
        margin-bottom: 62px;

        @media (max-width: 767px) {
            margin-bottom: 16px;
        }
    }

    &__title {
        margin-bottom: 20px;

        @media (max-width: 480px) {
            margin-bottom: 16px;
        }
    }

    &__body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 286px);
        gap: 42px;
        
        @media (max-width: 1024px) {
            gap: 20px;
            grid-template-rows: repeat(2, 245px);
        }
    }

    &__swiper.swiper {
        padding: 5px;
        margin-bottom: 11px;
    }

    &__pagination {
        display: flex;
        justify-content: center;
    }

    &__pagination-bullet {
        background-color: transparent;
        position: relative;
        width: 20px;
        height: 20px;
        padding: 14px;

        &:not(.is-active) {
            &:after {
                opacity: 0.5;
            }
        }

        &::after {
            content: url("../../shared/assets/image/icons/pagination-star.svg");
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: 0.25s;
            filter: var(--drop-shadow-corn);
        }
    }
}