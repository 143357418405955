.profile {
    &__project-progress {
        &-strip--fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            background: linear-gradient(to right, #00EE66 0%, #FCFF7B 100%);

            @media screen and (max-width: 767px) {
                height: 11px;
            }
        }
    }
}