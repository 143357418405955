.logo {
    display: inline-flex;

    &__body {
        display: inline-flex;
    }

    &__icon {
        @media (max-width: 767px) {
            width: 180px;
        }

        @media (max-width: 480px) {
            width: 121px;
        }
    }
}