.radio {
    &__wrapper {
        position: relative;
        display: inline-flex;
    }
    
    &__control {
        appearance: none;
        min-width: 195px;
        height: 47px;
        background-color: var(--color-dark-green);
        border: 1px solid var(--color-main-green);
        position: relative;
        transition: 0.3s;

        @media (max-width: 480px) {
            height: 32px;

        }

        &:hover {
            background-color: #044F2B;
        }

        &:checked {
            background-color: #044F2B;
            box-shadow: var(--box-shadow-green);
        }

        &-label {
            position: absolute;
            pointer-events: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            white-space: nowrap;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2;
        }
    }

    @media screen and (max-width: 767px) {
        
        &__control {
            min-width: 125px;
            width: 100%;

            &-label {
                font-size: 16px;
            }
        }

    }

    @media screen and (max-width: 480px) {
        &__control {
            min-width: 91px;
            width: 100%;

            &-label {
                font-size: 11px;
            }
        }
    }
}