.profile {

    &__project {
        color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #35373B;
        padding: 30px;
        text-decoration: none;
        gap: 89px;


        &-progress {
            display: flex;
            flex-direction: column;
            gap: 15px;
        
        
            &-text {
                display: flex;
                flex-direction: column;
                gap: 6px;
            
                &--top {
                    color: var(--color-gray);
                    font-family: "Exo2", sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.25;
                    letter-spacing: 0.01em;
                }
            
                &--bot {
                    font-family: "Exo2", sans-serif;
                    color: var(--color-main-white);
                    font-size: 23px;
                    font-weight: 600;
                    line-height: 1.2;
                }
            }
        
            &-strip {
                position: relative;
                width: 100%;
                height: 25px;
                background-color: #fff;
            }
        }
    }
    
    &__project-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    

    @media screen and (max-width: 1280px) {
        
        padding: 90px 0 80px;

        &__project {
            padding: 20px;
            gap: 65px;

            &-info {
                gap: 10px;
            }
        }

        .title--very-small {
            font-size: 28px;
        }

        .subtitle {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        
        padding: 20px 0;

        &__project {
            padding: 16px;
            gap: 12px;

            &-info {
                gap: 8px;
            }

            &-progress {

                gap: 8px;

                &-text {                
                    &--top {
                        font-size: 12px;
                    }
                
                    &--bot {
                        font-size: 16px;
                    }
                }

                &-strip {
                    height: 11px;
                }
            }
        }

        .title--very-small {
            font-size: 20px;
        }

        .subtitle {
            font-size: 16px;
        }

    }
}