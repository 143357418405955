.request {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
        display: block;
    }

    &__title {
        margin-bottom: 76px;
    }

    &__body {
        width: 645px;
    }

    &__inputs {
        display: grid;
        row-gap: 32px;
        margin-bottom: 30px;
    }

    &__error {
        margin-top: 8px;
        text-align: center;
    }

    @media (max-width: 767px) {
        &__title {
            margin-bottom: 16px;
        }

        &__body {
            width: 100%;
        }

        &__inputs {
            row-gap: 8px;
        }

        &__button {
            height: 43px;
        }
    }
}