.header {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;

    &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__list {
        display: flex;
        column-gap: 50px;
        align-items: center;
    }

    &__logout {
        display: flex;
        align-items: center;
        gap: 30px;

        @media (max-width: 480px) {
            gap: 15px;
        }

        &-image {
            cursor: pointer;
            width: 24px;
            height: 24px;
            object-fit: cover;
        }
    }
}