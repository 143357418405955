.profile {
    padding: 92px 60px 80px;

    &__inner {
        display: flex;
        flex-direction: column;
        gap: 62px; 
    }

    &__projects {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
    
    @media screen and (max-width: 767px) {
        .title {
            font-size: 28px;
        }

        &__inner {
            gap: 16px;
        }

        &__projects {
            grid-template-columns: 1fr;
            gap: 8px;
        }
    }
}