.burger-button {
    filter: var(--drop-shadow-corn);
    &.is-opened {
        .burger-button__icon {
            display: none;
        }
    }

    &:not(.is-opened) {
        .burger-button__is-opened-icon {
            display: none;
        }
    }
}