.error404 {
    padding-top: 78px;
    padding-bottom: 46px;
    
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__text {
        margin-bottom: 63px;

        @media (max-width: 767px) {
            text-align: center;
        }

        @media (max-width: 480px) {
            margin-bottom: 24px;
        }
    }

    &__button {
        max-width: 642px;
    }

    &__image {
        animation: motion-image 8s infinite alternate;
    }

    @keyframes motion-image {
        0% {
            transform: translate(0, 0);
        }

        20% {
            transform: translate(5px, -6px);
        }

        40% {
            transform: translate(-10px,6px);
        }

        60% {
            transform: translate(4px, -5px);
        }

        80% {
            transform: translate(-4px, 3px);
        }

        100% {
            transform: translate(-4px, -5px);
        }
    }
}