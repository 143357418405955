.project {
    &__form {
        max-width: 533px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (max-width: 767px) {
            max-width: 350px;
            width: 350px;
            gap: 12px;
        }

        @media (max-width: 480px) {
            max-width: 288px;
            width: 260px;
            gap: 8px;
        }

        &-textarea {
            min-height: 186px;
            border: 1px solid #F0EFF2;
            padding: 20px;
            resize: none;

            @media (max-width: 767px) {
                min-height: 160px;
            }

            @media (max-width: 480px) {
                min-height: 140px;
            }

            &::-webkit-scrollbar {
                width: 3px;
                background: none;
            }

            &::-webkit-scrollbar-thumb {
                background: #F0EFF2;
            }
        }

        &-buttons {
            display: flex;
            align-items: center;
            gap: 30px;

            @media (max-width: 767px) {
                gap: 20px;
            }

            @media (max-width: 480px) {
                column-gap: 12px;
            }
        }

        &-button {

            &--left {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 77px;
                min-height: 71px;
                color: #00EE66;
                text-shadow: 0 0 10px #00EE66;
                border: 1px solid var(--color-main-green);

                @media (max-width: 767px) {
                    height: 50px;
                    min-height: 50px;
                    width: 54px;
                }

                @media (max-width: 480px) {
                    min-height: 40px;
                    height: 40px;
                    width: 54px;
                }
            }

            &--right {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 71px;
                min-width: 415px;
                border: 1px solid var(--color-main-green);

                @media (max-width: 767px) {
                    min-height: 50px;
                    min-width: 0;
                    height: 50px;
                }

                @media (max-width: 480px) {
                    min-height: 40px;
                    min-width: 0;
                    height: 40px;
                }
            }
        }
    }
}