.portfolio-project {
    display: flex;
    height: 423px;
    max-width: 758px;
    border: 1px solid var(--color-main-white);

    @media (max-width: 767px) {
        flex-direction: column;
        width: 255px;
        min-height: 391px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 35px 26px 35px 26px;

        @media (max-width: 767px) {
            order: 1;
        }
        @media (max-width: 767px) {
            padding: 17px;
            height: 100%;
        }
    }

    &__tag {
        &-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        &-item {
            padding: 4px 10px 4px 10px;
            border: 1px solid var(--color-main-white);
        }
    }

    &__image {
        object-fit: cover;
        @media (max-width: 767px) {
            width: 100%;
            height: 100%;
        }

        &-wrapper {
            flex-shrink: 0;
            width: 375px;

            @media (max-width: 767px) {
                width: 100%;
                height: 176px;
            }
        }
    }
}