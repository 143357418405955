.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 30px;

    &__inputs-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 21px auto 23px;
    }

    &__link {
        text-decoration: none;
        color: #fff;
    }

    &__info {
        margin-bottom: 24px;
    }

    &__error {
        margin-top: 8px;
        text-align: center;
    }


    @media screen and (max-width:767px) {

        padding: 32px 16px;

        .title--small {
            font-size: 28px;
        }

        .text--small {
            font-size: 12px;
        }

        .button {
            height: 51px;
            font-size: 20px;
        }
    }
}