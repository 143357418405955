.services-row {
    --services-row-transition: 0.65s;
    position: relative;
    padding: 46px 68px 46px 68px;
    transition: var(--services-row-transition);

    &:hover {
        background-color: rgba(0, 238, 102, 0.1);

        .services-row__up, .services-row__down {
            &:after, &:before {
                opacity: 1;
            }
        }

        .services-row__image {
            opacity: 1;
            transform: translate(-50%,-5%);

            &.is-big {
                transform: translate(-50%,-5%);
            }
            
            &.is-arrow {
                transform: translate(-50%,-5%);
            }
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        max-width: 440px;
    }

    &__image {
        position: absolute;
        top: 8%;
        left: 50%;
        transform: translate(-50%,20%);
        opacity: 0;
        transition: var(--services-row-transition);
        z-index: -1;
        width: 20%;

        &.is-big {
            transform: translate(-60%,30%);
            width: 25%;
        }

        &.is-arrow {
            transform: translate(-60%,30%);
            width: 20%;
        }

    }

    &__text {
        max-width: 440px;
    }

    &__up, &__down {
        height: 0;

        &:after, &:before {
            position: absolute;
            width: 30px;
            height: 30px;
            content: "";
            background-image: url("../../../../shared/assets/image/icons/corn.svg");
            filter: var(--drop-shadow-corn);
            opacity: 0;
            transition: var(--services-row-transition);
        }
    }

    &__up {
        &::after {
            top: 0;
            left: 0;
            rotate: 90deg;
        }

        &::before {
            top: -0;
            right: 0;
            rotate: 180deg;
        }
    }

    &__down {
        &::after {
            bottom: 0;
            left: 0;
        }

        &::before {
            bottom: 0;
            right: 0;
            rotate: 270deg;
        }
    }

    @media screen and (max-width: 1366px) {

        &__image {
            width: 15%;
        }

        &__title {
            font-size: 34px !important; 
        }

        p {
            font-size: 25px;
        }
    }

    @media screen and (max-width: 1152px) {
        &__title {
            max-width: 40%;
            font-size: 16px !important; 
        }

        &__text {
            max-width: 50%;
        }

        p {
            font-size: 14px;
        }

        &__image {
            width: 11%;

            &.is-big {
                width: 17%;
            }

            &.is-arrow {
                width: 15%;
            }
        }

    }
    
    @media screen and (max-width: 767px) {

        padding: 20px 8px;

        &__up, &__down {
            height: 0;
    
            &:after, &:before {
                width: 18px;
                height: 18px;
                background-size: 18px;
            }
        }

        &__title {
            max-width: 40%;
            font-size: 12px !important; 
        }

        &__text {
            max-width: 50%;
        }

        p {
            font-size: 12px;
        }

        &__image {
            width: 10%;

            &.is-big {
                width: 15%;
            }

            &.is-arrow {
                width: 12%;
            }
        }
    }

    @media screen and (max-width: 630px) {
        &__image {
            width: 12%;

            &.is-big {
                width: 25%;
            }

            &.is-arrow {
                width: 20%;
            }
        }
    }

    @media screen and (max-width: 440px) {
        &__image {
            width: 20%;

            &.is-big {
                width: 30%;
            }

            &.is-arrow {
                width: 25%;
            }
        }
    }

    
    @media screen and (max-width: 375px) {
        &__image {
            width: 28%;

            &.is-big {
                width: 45%;
            }

            &.is-arrow {
                width: 35%;
            }
        }
    }
}