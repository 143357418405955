:root {
    --color-main-black: #060B11;
    --color-main-white: #F0EFF2;
    --color-main-green: #00EE66;
    --color-light-black: #17171A;
    --color-gray: #888888;
    --color-dark-gray: #35373B;
    --color-dark-green: #052219;
    --color-yellow: #FCFF7B;

    --box-shadow-green: 0px 0px 10px 0px #00EE6680;
    --drop-shadow-corn: drop-shadow(0px 0px 10px rgba(0, 238, 102, 0.5));
}