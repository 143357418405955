@font-face {
    font-family: 'Exo';
    src: url('../../shared/assets/fonts/Exo-ExtraLight.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('../../shared/assets/fonts/Exo-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../shared/assets/fonts/Exo2-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../shared/assets/fonts/Exo2-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../shared/assets/fonts/Exo2-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../shared/assets/fonts/Raleway-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'RockStar';
    src: url('../../shared/assets/fonts/RockStar-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
