.portfolio {
    padding-top: var(--section-padding-top);
    padding-bottom: var(--section-padding-bottom);

    &__inner {
        width: 95.9vw;
        margin-left: auto;
        padding-left: 15px;
    }

    &__project {
        &-list {
            display: flex;
            column-gap: 30px;
            padding-block: 5px;

            @media (max-width: 767px) {
                column-gap: 8px;
            }
        }

        &-item:last-child {
            padding-right: 50px;

            @media (max-width: 767px) {
                padding-right: 10px;
            }
        }
    }

    &__slider-wrapper {
        position: absolute;
        width: 100%;
    }

    &__title {
        margin-bottom: 60px;

        @media (max-width: 767px) {
            margin-bottom: 16px;
        }
    }
}