.project {
    &__progress {
        margin-bottom: 82px;

        @media (max-width: 767px) {
            margin-bottom: 28px;
        }

        &-title {
            margin-bottom: 20px;
        }

        &-text {
            margin-bottom: 67px;
        }
    }

    &__links-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;

        @media (max-width: 767px) {
            column-gap: 15px;
        }

        @media (max-width: 480px) {
            column-gap: 8px;
        }
    }
}