.visible-mobile {
    @media (min-width: 768px) {
        display: none !important;
    }
}

.hidden-mobile {
    @media (max-width: 767px) {
        display: none !important;
    }
}

.container {
    --container-padding-inline: 60px;
    max-width: calc(1320px + (var(--container-padding-inline) * 2));
    margin-inline: auto;
    padding-inline: var(--container-padding-inline);

    @media (max-width: 1024px) {
        --container-padding-inline: 30px;
    }

    @media (max-width: 480px) {
        --container-padding-inline: 16px;
    }
}

.title {
    font-family: "RockStar", sans-serif;
    font-size: 54px;
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: 480px) {
        font-size: 28px;
    }

    &--very-big {
        font-size: 164px;

        @media (max-width: 1700px) {
            font-size: 112px;
        }

        @media (max-width: 1024px) {
            font-size: 80px;
        }

        @media (max-width: 767px) {
            font-size: 50px;
        }

        @media (max-width: 480px) {
            font-size: 36px;
        }
    }

    &--small {
        font-size: 44px;
    }

    &--very-small {
        font-size: 34px;

        @media (max-width: 767px) {
            font-size: 20px;
        }
    }

    &--tiny {
        font-size: 34px;
    }

    &--green {
        color: var(--color-main-green);
        filter: var(--drop-shadow-corn);
    }
}

.subtitle {
    font-family: "Exo2", sans-serif;
    color: var(--color-main-white);
    font-size: 25px;
    font-weight: 400;
    line-height: 1.2;
}

.text {
    font-family: "Exo2", sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: 0.01em;

    @media (max-width: 767px) {
        font-size: 22px;
    }

    @media (max-width: 480px) {
        font-size: 16px;
    }

    &--gray {
        color: var(--color-gray);
    }

    &--rock-star {
        font-family: "RockStar", sans-serif;
    }

    &--white {
        color: #fff;
    }

    &--small {
        font-size: 20px;
    }

    &--green {
        color: #00EE66;
    }

    &--black {
        color: #060B11;
    }

    &--very-small {
        font-size: 16px;
    }

    &--medium-size {
        font-size: 25px;

        @media (max-width: 767px) {
            font-size: 20px;
        }

        @media (max-width: 480px) {
            font-size: 16px;
        }
    }

    &--weight-400 {
        font-weight: 400;
    }

    &--not-ls {
        letter-spacing: 0;
    }
}

.link {
    font-family: "Exo2", sans-serif;
    color: var(--color-main-white);
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: 1024px) {
        font-size: 18px;
    }

    &--small {
        font-size: 23px;

        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    &--regular {
        font-weight: 400;
    }

    &--medium {
        font-weight: 500;
    }

    &--regular-small {
        font-weight: 400;
        font-size: 23px;
    }

    &--black {
        color: #060B11;
    }
}

.error {
    font-family: "Exo", sans-serif;
    color: var(--color-main-green);
    filter: var(--drop-shadow-corn);
    font-size: 18px;

    @media (max-width: 767px) {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
}