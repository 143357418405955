.leave-request {
    padding-top: 150px;
    padding-bottom: 100px;

    .title {
        font-size: clamp(28px, 5vw, 54px);
    }

    &__inner {
        display: flex;
        justify-content: center;
        position: relative;
    }

    &__body {
        padding-top: 25px;
        padding-bottom: 35px;
    }

    &__up, &__down {

        height: 0;

        &::after, &::before {
            position: absolute;
            content: url("../../shared/assets/image/icons/corn.svg");
            filter: var(--drop-shadow-corn);
            width: 29px;
            height: 30px;
        }
    }

    &__up {
        &::after {

            top: 0;
            left: var(--container-padding-inline);
            rotate: 90deg;
            transform: translate(-17%,17%);
        }

        &::before {
            top: -1px;
            right: var(--container-padding-inline);
            rotate: 180deg;
            transform: translate(-17%,17%);
        }
    }

    &__down {
        &::after {
            bottom: 0;
            left: var(--container-padding-inline);
            transform: translate(-17%,17%);

        }

        &::before {
            bottom: 0;
            right: var(--container-padding-inline);
            rotate: 270deg;
            transform: translate(-17%,17%);
        }
    }

    @media screen and (max-width: 1024px) {

        &__up, &__down {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {

        padding-block: 64px;

        &__body {
            padding: 0;
        }
    }
}